import { ref, Ref } from 'vue'
import AgentResponse = models.server.api.international.agent.AgentResponse
import ApiQueryBody = models.server.api.international.ApiQueryBody
import AggregatedAgents = models.server.api.international.predictive.AggregatedAgents
import ApiResponse = models.server.api.international.ApiResponse

export default function () {
  const countries: Ref<string[]> = ref([])
  const cities: Ref<string[]> = ref([])
  const agentCountry = ref('All countries')
  const agentCity = ref('All cities')
  const agents: Ref<{ name: string; id: string }[]> = ref([])

  const bodyRequest: Ref<ApiQueryBody> = ref({
    id: 'is-search-agents',
    params: {
      from: 0,
      size: 50
    }
  })

  const updateAgentQuery = (input: string | undefined) => {
    bodyRequest.value.params.query_string = `${input}`
    input && input.length > 0
      ? fetchAgents('is-suggest-agents')
      : clearAgentSelect()
  }

  const updateAgentFilter = (
    filterKey: 'city' | 'country',
    input: string | undefined
  ) => {
    try {
      switch (filterKey) {
        case 'country':
          // so if its a country need to reset cities and also fetch the cities list again
          agentCountry.value = `${input}`
          agentCity.value = 'All cities'
          delete bodyRequest.value.params.city
          bodyRequest.value.params.country = `${input}`
          fetchCountriesCities(`${input}`)
          break
        case 'city':
          agentCity.value = `${input}`
          delete bodyRequest.value.params.country
          bodyRequest.value.params.city = `${input}`
          break
      }

      return fetchAgents('is-search-agents')
    } catch (e) {
      console.error({ e })
    }
  }

  const fetchCountriesCities = async (query_string: string) => {
    const url = `/api/international/agent`
    const request: ApiQueryBody = {
      id: 'is-prefill-agents',
      params: { query_string }
    }
    const apiResponse = await $fetch<ApiResponse>(url, {
      method: 'POST',
      body: request
    })
    const data = apiResponse.aggregations as AggregatedAgents | undefined
    if (query_string === '')
      countries.value =
        data?.countries?.buckets
          .map((b) => b.key)
          .filter((key) => key.length > 1) || []
    cities.value =
      data?.cities?.buckets.map((b) => b.key).filter((key) => key.length > 1) ||
      []
  }

  const fetchAgents = async (
    id:
      | 'is-search-agents'
      | 'is-search-courses-v1.0'
      | 'is-suggest-courses-v1.0'
      | 'is-prefill-agents'
      | 'is-suggest-agents'
  ) => {
    const url = `/api/international/agent`
    bodyRequest.value.id = id
    try {
      const apiResponse = await $fetch<ApiResponse>(url, {
        method: 'POST',
        body: bodyRequest.value
      })

      const data = apiResponse?.hits?.hits as AgentResponse[]
      const mapped = data.map((r) => ({
        name: r._source.name,
        id: r._id
      }))
      agents.value = mapped
    } catch (e) {
      console.error({ e })
    }
  }

  const clearAgentSelect = () => {
    agents.value = []
    agentCountry.value = 'All countries'
    agentCity.value = 'All cities'
    delete bodyRequest.value.params.city
    delete bodyRequest.value.params.country
    delete bodyRequest.value.params.filters
    delete bodyRequest.value.params.query_string
  }

  return {
    agents,
    agentCountry,
    agentCity,
    countries,
    cities,
    updateAgentFilter,
    updateAgentQuery,
    fetchAgents,
    fetchCountriesCities,
    clearAgentSelect
  }
}
